import { fetchBundleData, FetchBundleDataOptions } from './bundle_api'
import { useState, useEffect } from 'react'
import LRU from 'lru-cache'
import { getHash } from '../../utils/Crypto'

const cache = new LRU({max: 100})

export const useBundleData = (options: FetchBundleDataOptions) => {
  const [bundleData, setBundleData] = useState(undefined)

  const signature = getHash(options)

  useEffect(() => {
    const cacheResult = cache.get(signature)

    if (typeof cacheResult !== 'undefined') {
      setBundleData(cacheResult)
    } else {
      fetchBundleData(options).then(response => {
        if (response.error > 0) {
          setBundleData({ error: response.data })
        } else {
          cache.set(signature, response.data)
          setBundleData(response.data)
        }
      })
    }
  }, [signature])

  return bundleData
}
